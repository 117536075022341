/* eslint-disable jsx-a11y/img-redundant-alt */
import './App.css';
import logo from "./images/zero2logo.svg"
import picture from "./images/picture.png"
import zetaryalogo from "./images/zetarya.svg"

function App() {

  const visit = (url) => {
    window.open(url);
  }
  

  return (
    <div className="App">
      <nav className="nav">
          <p onClick={() => visit("https://www.zero2.in")}>zero2</p>
      </nav>
      <hr style={{"border-top": "1px solid #D9D9D9"}} />
      <div className="main" >
        <div className="content">
          <div className="logo-mission">
            <img src={logo} alt='zero2 logo' />
            <h1 className="together_we_are_complete">Together we are complete</h1>
          </div>

          <div className="mission">
            <h1>Our mission is to provide better softwares <br />which starts with <span className="privacy">privacy</span> and <span className="freedom">freedom</span>.</h1>
            <span className='line'>Softwares designed to provide seamless integration with your existing pipeline.</span>
          </div>
          {/* <div className="line">
          </div> */}
        </div>
        <div className="picture">
          <img src={picture} alt="picture" />
        </div>
      </div>
      
      {/* <hr style={{"border-top": "1px solid #D9D9D9"}} /> */}
      <footer>
        <div className='zetarya'>
          <img src={zetaryalogo} alt="picture" onClick={() => visit("https://www.zetarya.com")} />
        </div>
      </footer>
      <div className="footer">
        <span>Copyright © 2024 zero2 <span className='tm'>&reg;</span></span>
        <span>info@zero2.in</span>
        <div className='privacy-policy'>
            <span onClick={() => visit("/privacy")}>Privacy Policy</span>
            {/* <span>Terms</span> */}
        </div>
      </div>
    </div>
  );
}

export default App;
