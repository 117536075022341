import React from 'react';
import './PrivacyPolicy.css';


export function Privacy() {

    const visit = (url) => {
        window.open(url);
    }

    return (
        <>
        <nav className="nav">
                <p onClick={() => visit("https://www.zero2.in")}>zero2</p>
            </nav>
            <hr style={{"border-top": "1px solid #D9D9D9"}} />
        <div className="privacyPolicy">
            <h1>Privacy Policy</h1>
            <p>Last updated: July 05, 2024</p>
            <p>Welcome to zero2. This Privacy Policy describes how zero2 ("we", "us", "our") collects, uses, and discloses your information when you use our services and tells you about your privacy rights and how the law protects you. Our legal name is Zeero Two Technova Pvt. Ltd, located at C-65 Sudarshanpura Industrial Area, Jaipur, Rajasthan, 302006, India.</p>
            <p>By using our services, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
            
            <h2>Interpretation and Definitions</h2>
            <h3>Interpretation</h3>
            <p>Words with capitalized initial letters have specific meanings. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.</p>
            
            <h3>Definitions</h3>
            <ul>
                <li><strong>Account</strong>: A unique account created for you to access our service or parts of our service.</li>
                <li><strong>Company</strong>: Refers to Zeero Two Technova Pvt. Ltd.</li>
                <li><strong>Cookies</strong>: Small files placed on your device by a website, containing details of your browsing history among other uses.</li>
                <li><strong>Country</strong>: Refers to Rajasthan, India.</li>
                <li><strong>Device</strong>: Any device that can access the service such as a computer, a cellphone, or a digital tablet.</li>
                <li><strong>Personal Data</strong>: Any information that relates to an identified or identifiable individual.</li>
                <li><strong>Service</strong>: Refers to the Website operated by zero2.</li>
                <li><strong>Service Provider</strong>: Any natural or legal person who processes the data on behalf of the company.</li>
                <li><strong>Usage Data</strong>: Data collected automatically, either generated by the use of the service or from the service infrastructure itself.</li>
                <li><strong>Website</strong>: zero2, accessible from <a href="https://www.zero2.in/" rel="external nofollow noopener">https://www.zero2.in/</a></li>
                <li><strong>You</strong>: The individual accessing or using the service, or the company, or other legal entity on behalf of which such individual is accessing or using the service.</li>
            </ul>
            
            <h2>Collecting and Using Your Personal Data</h2>
            <h3>Types of Data Collected</h3>
            <h4>Personal Data</h4>
            <p>While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. This may include, but is not limited to:</p>
            <ul>
                {/* <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li> */}
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>Usage Data</li>
            </ul>
            
            <h4>Usage Data</h4>
            <p>Usage Data is collected automatically when using the service.</p>
            <p>Usage Data may include information such as your device's IP address, browser type, browser version, the pages of our service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
            
            <h4>Tracking Technologies and Cookies</h4>
            <p>We use cookies and similar tracking technologies to track activity on our service and store certain information. The technologies we use may include:</p>
            <ul>
                <li><strong>Cookies or Browser Cookies</strong>: A cookie is a small file placed on your device. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our service.</li>
                <li><strong>Web Beacons</strong>: Certain sections of our service and our emails may contain small electronic files known as web beacons that permit the company to count users who have visited those pages or opened an email and for other related website statistics.</li>
            </ul>
            
            <h3>Use of Your Personal Data</h3>
            <p>We may use your personal data for the following purposes:</p>
            <ul>
                <li>To provide and maintain our service</li>
                <li>To manage your account</li>
                <li>For the performance of a contract</li>
                <li>To contact you</li>
                <li>To provide you with news and special offers</li>
                <li>To manage your requests</li>
                <li>For business transfers</li>
                <li>For other purposes such as data analysis, identifying usage trends, and improving our service</li>
            </ul>
            
            <h3>Retention of Your Personal Data</h3>
            <p>We will retain your personal data only for as long as necessary for the purposes set out in this privacy policy. We will retain and use your personal data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our agreements and policies.</p>
            
            <h3>Transfer of Your Personal Data</h3>
            <p>Your information, including personal data, is processed at our operating offices and in any other places where the parties involved in the processing are located. This means your information may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where data protection laws may differ from those in your jurisdiction.</p>
            <p>Your consent to this privacy policy followed by your submission of such information represents your agreement to that transfer.</p>
            
            <h3>Delete Your Personal Data</h3>
            <p>You have the right to delete or request that we assist in deleting the personal data that we have collected about you. Our service may give you the ability to delete certain information about you from within the service. You may also contact us to request access to, correct, or delete any personal information that you have provided to us.</p>
            
            <h3>Disclosure of Your Personal Data</h3>
            <h4>Business Transactions</h4>
            <p>If we are involved in a merger, acquisition, or asset sale, your personal data may be transferred. We will provide notice before your personal data is transferred and becomes subject to a different privacy policy.</p>
            <h4>Law Enforcement</h4>
            <p>Under certain circumstances, we may be required to disclose your personal data if required to do so by law or in response to valid requests by public authorities.</p>
            <h4>Other Legal Requirements</h4>
            <p>We may disclose your personal data in the good faith belief that such action is necessary to:</p>
            <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the company</li>
                <li>Prevent or investigate possible wrongdoing in connection with the service</li>
                <li>Protect the personal safety of users of the service or the public</li>
                <li>Protect against legal liability</li>
            </ul>
            
            <h3>Security of Your Personal Data</h3>
            <p>The security of your personal data is important to us, but no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.</p>
            
            <h2>Children's Privacy</h2>
            <p>Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us. If we become aware that we have collected personal data from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from our servers.</p>
            
            <h2>Links to Other Websites</h2>
            <p>Our service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the privacy policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
            
            <h2>Changes to This Privacy Policy</h2>
            <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. We will let you know via email and/or a prominent notice on our service, prior to the change becoming effective and update the "Last updated" date at the top of this privacy policy. You are advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page.</p>
            
            <h2>Contact Us</h2>
            <p>If you have any questions about this privacy policy, you can contact us:</p>
            <ul>
                <li>By email: office@zero2.in</li>
            </ul>
        </div>

        </>
    );
}
